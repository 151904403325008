<template>
  <standard-page :title="process_definition_step.name" status="Pending" v-if="process_definition_step">
    <template v-if="currentRouteName === 'view-process-step'" v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'provenances-registries'}">Registries</router-link>
      </span>
      <span v-if="registry">
        <router-link :to="{ name: 'processdefinitions'}">Registry: {{registry.name | truncate(5, '.....')}}</router-link>
      </span>
      <span v-if="processDefinition">
        <router-link :to="{ name: 'process-definition-steps'}">Process Definition: {{processDefinition.name | truncate(5, '.....')}}</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'process'}">Processes</router-link>
      </span>
      <span v-if="process">
        <router-link :to="{ name: 'process-steps'}">Process: {{process.name | truncate(5, '.....')}}</router-link>
      </span>
      <span v-if="process_definition_step">Step: {{process_definition_step.name | truncate(10, '.....')}}</span>
    </template>
    <template v-else v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'ongoing-process'}">Ongoing Processes</router-link>
      </span>
      <span v-if="process">
        <router-link :to="{ name: 'process-steps'}">Process: {{process.name | truncate(10, '.....')}}</router-link>
      </span>
      <span v-if="process_definition_step">Step: {{process_definition_step.name | truncate(10, '.....')}}</span>
    </template>
    <template v-slot:content>
      <div class="az-content-label mg-b-5">Description</div>
      <div class="mg-b-20" v-html="process_definition_step.description?process_definition_step.description:'NA'"></div>

    </template>
  </standard-page>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ViewFutureProcessStep",
  computed: {
    ...mapGetters(["currentUser"]),
    currentRouteName() {
      return this.$route.name;
    }
  },
  props: ['registryid', 'processdefinitionid', 'processid', 'processdefinitionstepid'],
  data() {
    return {
      registry: null,
      processDefinition: null,
      process: null,
      process_definition_step: null
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getProcess();
      await this.getProcessStep();
      if (this.currentRouteName === 'view-process-step') {
        await this.getRegistry();
        await this.getProcessDefinition();
      }
    },
    async getRegistry() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}`);
        this.registry = data;
      } catch (e) {
        this.$toastr.e("Error retrieving Registry", "Error");
      } finally {

      }
    },
    async getProcessDefinition() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}`);
        this.processDefinition = data;
      } catch (e) {
        this.$toastr.e("Error retrieving Process Definition", "Error");
      } finally {

      }
    },
    async getProcess() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}`);
        this.process = data;
      } catch (e) {

      } finally {
      }
    },
    async getProcessStep() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/definition_steps/${this.processdefinitionstepid}`);
        this.process_definition_step = data;
      } catch (e) {
        this.$toastr.e("Error retrieving Process Definition", "Error");
      } finally {

      }
    }
  }
}
</script>

<style scoped>
</style>